import { useEffect, useState } from 'react'
import NextLink from 'next/link'
import {
  Accordion,
  AccordionItem,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Link,
  VStack,
} from '@chakra-ui/react'
import { boxShadow } from 'composable/chakra/figma-tokens'
import { useFormat } from 'helpers/hooks/useFormat'
import { BsTruck } from 'react-icons/bs'
import { IoStorefrontOutline } from 'react-icons/io5'
import { MegaDrawerItem } from './MegaDrawerItem'
import OrderStatusModal from '../account/pages/account-dashboard/components/order-status'
import { BrandLogo } from '../brand-logo'
import { useComposable } from '../composable-provider'
import { SITE_CONFIG_CONTENT_TYPE, SITE_CONFIG_NAME } from '../general/constants'
import useContentstack, { Entry } from '../hooks/useContentstack'

export interface MegaDrawerProps {
  items: Entry[]
}

export interface SiteConfig {
  brand_logo_small?: {
    url: string
  }
  name: string
}

export const MegaDrawer = ({ items }: MegaDrawerProps) => {
  const [siteConfig, setSiteConfig] = useState<SiteConfig>()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { megaDrawer, path } = useComposable()
  const { getEntryByName } = useContentstack()
  const { formatMessage } = useFormat({ name: 'common' })
  const [isOrderStatusModalOpen, setIsOrderStatusModalOpen] = useState(false)

  useEffect(() => {
    getPageConfig()
  }, [])

  useEffect(() => {
    if (megaDrawer.isOpen && !isOpen) {
      setIsOpen(true)
    }
  }, [megaDrawer.isOpen])

  const getPageConfig = async () => {
    const result = await getEntryByName(SITE_CONFIG_CONTENT_TYPE, SITE_CONFIG_NAME)
    setSiteConfig(result)
  }

  const onCloseHandler = () => {
    setIsOpen(false)
    setTimeout(() => {
      megaDrawer.onClose()
    }, 400)
  }

  const openDxlStore = () => {
    window.open('https://stores.dxl.com/search', '_blank')
  }

  const handleOpenOrderStatusModal = () => {
    setIsOrderStatusModalOpen(true)
  }

  const handleCloseOrderStatusModal = () => {
    setIsOrderStatusModalOpen(false)
  }

  if (!items || !siteConfig) return null

  const headerLogo = siteConfig?.brand_logo_small

  return (
    <Drawer isOpen={isOpen} onClose={onCloseHandler} placement="left" size={'full'}>
      <DrawerOverlay />
      <DrawerContent height={'100dvh'} animation="ease-in-out" transition="ease-in-out">
        <DrawerHeader
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          height={'3rem'}
          mb={6}
          py={3}
          px={2}
          borderBottom={'1px solid var(--semantic-surface-border, #E7EAED)'}
        >
          {headerLogo && (
            <Link href={path.getHome()} onClick={onCloseHandler}>
              <BrandLogo src={headerLogo.url} alt={siteConfig.name ?? undefined} height={24} width={42.8} />
            </Link>
          )}
          <DrawerCloseButton
            style={{
              position: 'initial',
              fontSize: 12,
            }}
            _hover={{
              bg: 'none',
            }}
          />
        </DrawerHeader>
        <DrawerBody py={0} px={4} pb={32}>
          <VStack alignItems={'flex-start'} gap={3}>
            <Accordion allowToggle gap={2} display={'flex'} flexDirection={'column'} w="100%">
              {items?.map((item: Entry) => {
                const key = item?.uid
                return (
                  <AccordionItem
                    key={key}
                    pt={0}
                    _hover={{
                      bg: 'none',
                    }}
                    borderTop={0}
                    borderBottom={'1px solid var(--semantic-surface-highlight, #E1E5E8)'}
                    height={'2.813rem'}
                  >
                    <MegaDrawerItem key={key + '_item'} item={item} closeAll={onCloseHandler} />
                  </AccordionItem>
                )
              })}
            </Accordion>
            <VStack
              position="fixed"
              bottom={0}
              left={0}
              right={0}
              zIndex={20}
              display={'flex'}
              gap={'5px'}
              mt={'var(--chakra-space-2)'}
              justifyContent={'left'}
              alignItems={'flex-start'}
              py={'var(--chakra-space-3)'}
              px={4}
              backgroundColor="white"
              borderBottom={'1px solid var(--semantic-surface-highlight, #E1E5E8)'}
              boxShadow={boxShadow['sm-inverseY']}
            >
              <Accordion allowToggle gap={2} display={'flex'} flexDirection={'column'} w="100%">
                <Button
                  px={0}
                  height={'48px'}
                  variant="ghost"
                  textDecoration="none"
                  name="order-status"
                  size="sm"
                  aria-label={'Stores'}
                  minW={'40px'}
                  leftIcon={<IoStorefrontOutline size="26px" color="#111111" />}
                  display="flex"
                  justifyContent="flex-start"
                  onClick={openDxlStore}
                >
                  {formatMessage({ id: 'megamenu.details.stores' })}
                </Button>
              </Accordion>
              <Accordion allowToggle gap={2} display={'flex'} flexDirection={'column'} w="100%">
                <Button
                  px={0}
                  height={'48px'}
                  variant="ghost"
                  textDecoration="none"
                  name="order-status"
                  size="sm"
                  aria-label={'Order Status'}
                  minW={'40px'}
                  leftIcon={<BsTruck size="26px" color="#111111" />}
                  onClick={handleOpenOrderStatusModal}
                  display="flex"
                  justifyContent="flex-start"
                >
                  {formatMessage({ id: 'megamenu.details.order.Status' })}
                </Button>
              </Accordion>
            </VStack>
          </VStack>
        </DrawerBody>

        {isOrderStatusModalOpen && (
          <OrderStatusModal isOpen={isOrderStatusModalOpen} onClose={handleCloseOrderStatusModal} />
        )}
      </DrawerContent>
    </Drawer>
  )
}
