import { useEffect, useState } from 'react'
import { Skeleton, useBreakpointValue } from '@chakra-ui/react'
import { ContentstackMegaMenu as ContentstackMegaMenuProps } from '@Types/contentstack'
import { MegaDrawer } from 'composable/components/mega-drawer/MegaDrawer'
import useContentstack, { Entry } from '../hooks/useContentstack'
import { MegaMenu } from '../mega-menu/MegaMenu'

export const MEGA_MENU_ITEM = 'mega_menu_item'
export const MEGA_MENU_ITEM_GROUP = 'mega_menu_item_group'
export const MEGA_MENU_COVER_CARD = 'component_cover_card'

export const ContentstackMegaMenu = ({ items }: ContentstackMegaMenuProps) => {
  const [contentList, setContentList] = useState<Entry[]>([])
  const { getEntryByUid } = useContentstack()

  const isMobile = useBreakpointValue({ base: true, lg: false }, { fallback: 'lg' })

  useEffect(() => {
    getMegaMenuContent(items)
  }, [])

  const getMegaMenuContent = async (items) => {
    try {
      const itemsResults = items?.map((item) => getEntryByUid(MEGA_MENU_ITEM, item.uid))
      const results = await Promise.all(itemsResults)
      setContentList(results)
    } catch (err) {
      console.log('Error processing Mega Menu data', err)
    }
  }

  return isMobile ? (
    <MegaDrawer items={contentList} />
  ) : (
    <MegaMenu isLoading={!contentList.length} items={contentList} />
  )
}
