import { useState } from 'react'
import { Box, Button, HStack, Skeleton } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { MegaMenuDetailsBar } from './MegaMenuDetailsBar'
import { MegaMenuItem } from './MegaMenuItem'
import { Entry } from '../hooks/useContentstack'
import { refMyStore } from '../mystore/my-store'

export interface MegaMenuProps {
  isLoading?: boolean
  items: Entry[]
}

export const MegaMenu = ({ isLoading, items }: MegaMenuProps) => {
  const [isFocus, setIsFocus] = useState(false)
  const { formatMessage } = useFormat({ name: 'common' })

  if (isLoading) {
    return <Skeleton h="70px" />
  }

  return (
    <>
      <Box
        as="nav"
        id="mega-menu"
        aria-label="Mega Menu"
        position="relative"
        zIndex="3"
        display={{ base: 'none', lg: 'flex' }}
        flexDirection="column"
        alignItems="center"
      >
        <HStack position="relative" width="100%" justifyContent="center" alignItems="stretch" spacing={2}>
          <Button
            left={0}
            top={2}
            position="absolute"
            opacity={isFocus ? 1 : 0}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            variant="link"
            size="sm"
            color="shading"
            textDecoration="underline"
            onClick={() => refMyStore.current?.focus()}
          >
            {formatMessage({ id: 'megamenu.details.skipNavigation' })}
          </Button>
          {items?.map((item, idx) => <MegaMenuItem key={`${idx}-${item?.uid}`} item={item} />)}
        </HStack>
      </Box>
      <Box width="100%" bg="brand.muted">
        <MegaMenuDetailsBar />
      </Box>
    </>
  )
}
