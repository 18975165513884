import { AccordionItem, SimpleGrid, useBreakpointValue } from '@chakra-ui/react'
import { MegaDrawerItem } from './MegaDrawerItem'
import { ContentstackCoverCard } from '../contentstack/cover-card'
import { MEGA_MENU_COVER_CARD, MEGA_MENU_ITEM } from '../contentstack/mega-menu'

interface MegaDrawerItemGroupProps {
  data: any[]
  closeAll: () => void
}

export const MegaDrawerItemGroup = ({ data, closeAll }: MegaDrawerItemGroupProps) => {
  const isMobile = useBreakpointValue({ base: true, sm: false })

  const coverCardsArr = []
  const menuItemsArr = []

  data?.map((item: any) => {
    item?.children?.map((eachItem: any) => {
      if (eachItem?._content_type_uid === MEGA_MENU_COVER_CARD) {
        coverCardsArr.push(eachItem)
      } else if (eachItem?._content_type_uid === MEGA_MENU_ITEM) {
        menuItemsArr.push(eachItem)
      }
    })
  })

  const parsedMenuItems = menuItemsArr?.map((item: any) => (
    <AccordionItem key={item?.uid} borderTop={0} borderBottom={0}>
      <MegaDrawerItem item={item} closeAll={closeAll} isSubItem />
    </AccordionItem>
  ))

  const parsedCoverCards = coverCardsArr?.map((item: any, idx: number) => (
    <ContentstackCoverCard
      key={item.uid}
      {...item}
      root={{
        minHeight: '200px',
        px: 4,
        py: 6,
      }}
      onClickHandler={closeAll}
    />
  ))

  return (
    <>
      {parsedMenuItems.length > 0 &&
        parsedMenuItems.map((item: any) => (
          <AccordionItem key={item?.uid} borderTop={0} borderBottom="1px solid" borderBottomColor="surface.highlight">
            {item}
          </AccordionItem>
        ))}
      {parsedCoverCards.length > 0 && (
        <SimpleGrid width="100%" columns={isMobile ? 1 : 2} gap={2} mt={2}>
          {parsedCoverCards.map((item: any) => (
            <AccordionItem key={item?.uid} borderTop={0} borderBottom="1px solid" borderBottomColor="surface.highlight">
              {item}
            </AccordionItem>
          ))}
        </SimpleGrid>
      )}
    </>
  )
}
