import { RefObject } from 'react'
import NextLink from 'next/link'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Button, Text } from '@chakra-ui/react'
import { useComposable } from '../composable-provider'

interface MegaMenuButtonProps {
  label: string
  onOpen: () => void
  btnRef: RefObject<HTMLButtonElement>
  isSubItem?: boolean
  isHiglighted?: boolean
  href?: string
  closeAll: () => void
}

export const MegaDrawerButton = ({ label, onOpen, btnRef, href, closeAll }: MegaMenuButtonProps) => {
  const { megaDrawer } = useComposable()
  return (
    <Box cursor="pointer" height={'2.813rem'} display="flex" justifyContent="space-between" alignItems="center">
      <Button display="flex" flex={1} variant="unstyled" {...(!!href && { as: NextLink, href })} onClick={closeAll}>
        <Text
          width={'100%'}
          display={'flex'}
          alignContent={'center'}
          textStyle={{ base: 'body-75' }}
          my={3}
          alignSelf={'center'}
          color={'var(--semantic-text-primary, #15191C)'}
        >
          {label}
        </Text>
      </Button>
      <Button ref={btnRef} onClick={onOpen} variant="unstyled" display="flex" justifyContent="flex-end">
        <ChevronRightIcon fontSize={24} color={'heading'} />
      </Button>
    </Box>
  )
}
